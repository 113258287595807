import Divider from '@mui/material/Divider';
import React from 'react';
import pic from '/static/logo_500.webp';
import useFetchImage from 'hooks/useFetchImages';
import { useProjectTextContext } from 'context/ProjectTextContext';

interface FormLogoProps {
  dividerStyle?: React.CSSProperties;
}

const FormLogo: React.FC<FormLogoProps> = ({ dividerStyle }) => {
  const imageUrl = useFetchImage('BRAND_LOGO', pic);
  const { PROJECT_NAME: name } = useProjectTextContext();
  return (
    <>
      <img
        src={imageUrl}
        style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '14px', width: '95px' }}
        alt="ths-logo"
      />
      <p style={{ textAlign: 'center', lineHeight: '28px' }}>{name}</p>
      <Divider style={dividerStyle} />
    </>
  );
};

export default FormLogo;
