import { styled } from '@mui/material';
import Paper from '@mui/material/Paper';
import useIsMobile from 'hooks/useIsMobile';
import React from 'react';

const RootPaper = styled(Paper)(({ theme }) => ({
  margin: '0 auto',
  maxWidth: '500px',
  padding: '16px 30px',
  [theme.breakpoints.up('md')]: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '5px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '16px 0px',
  },
}));

interface FormContainerProps {
  children: React.ReactNode;
  elevation?: number;
}

const FormContainer: React.FC<FormContainerProps> = ({ children, elevation }) => {
  const isMobile = useIsMobile();
  return <RootPaper elevation={elevation && !isMobile ? elevation : 0}>{children}</RootPaper>;
};

export default FormContainer;
