import { Typography } from '@mui/material';
import React from 'react';

interface ErrorEmailProps {
  errorMessage: string;
}

const ErrorEmail: React.FC<ErrorEmailProps> = ({ errorMessage }) => {
  return (
    <>
      <Typography variant="h6" style={{ marginTop: '16px' }}>
        Error
      </Typography>
      <p style={{ marginTop: '5px', marginBottom: '16px' }}>{errorMessage}</p>
    </>
  );
};

export default ErrorEmail;
