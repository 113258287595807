import { Button, Typography } from '@mui/material';
import React from 'react';

interface SuccessEmailProps {
  handleClick: () => void;
}

const SuccessEmail: React.FC<SuccessEmailProps> = ({ handleClick }) => {
  return (
    <>
      <Typography variant="h6" style={{ marginTop: '16px' }}>
        Thank you!
      </Typography>
      <p style={{ marginTop: '5px', marginBottom: '16px' }}>Your account has been successfully created!</p>
      <Button
        style={{ width: '100%', marginBottom: '40px' }}
        onClick={handleClick}
        variant="contained"
        color="secondary"
      >
        Continue
      </Button>
    </>
  );
};

export default SuccessEmail;
