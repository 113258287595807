import useProjectId from 'hooks/useProjectId';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorEmail from './ErrorEmail';
import SuccessEmail from './SuccessEmail';

interface Props {
  isError: boolean;
}

const ErrorOrSuccessEmail: React.FC<Props> = ({ isError }) => {
  const navigate = useNavigate();
  const projectId = useProjectId();

  if (isError) {
    return <ErrorEmail errorMessage="There was an error confirming your E-mail" />;
  }
  return <SuccessEmail handleClick={() => navigate(`/${projectId}/login`)} />;
};

export default ErrorOrSuccessEmail;
