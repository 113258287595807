import React from 'react';

import { CircularProgress } from '@mui/material';
import FormLogo from 'components/formLogo/FormLogo';
import useGetUserConfirmation from 'queries/user/user-get-confirm';
import ErrorOrSuccessEmail from './components/ErrorOrSuccessEmail';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';
import FormContainer from '/components/formContainer/FormContainer';

const ConfirmEmail: React.FC = () => {
  const confirmEmail = useGetUserConfirmation({ token: new URLSearchParams(window.location.search).get('token') });

  if (confirmEmail.isLoading) return <CircularProgress color="secondary" />;

  return (
    <>
      <Content greyBackground>
        <FormContainer elevation={4}>
          <FormLogo />
          <ErrorOrSuccessEmail isError={confirmEmail.isError} />
        </FormContainer>
      </Content>
      <Footer />
    </>
  );
};

export default ConfirmEmail;
