import { useQuery } from '@tanstack/react-query';
import * as Backend from 'queries/BackendRequest';

interface Props {
  token: string | null;
}

const getConfirmation = async ({ token }: Props) => {
  if (!token) {
    throw Error('No token provided');
  }
  await Backend.request('get', '/confirm', { token }, {});
  return null;
};

const getUserConfirmation = (props: Props) => ({
  queryKey: ['getUserConfirmation', props.token],
  queryFn: () => getConfirmation(props),
});

const useGetUserConfirmation = (props: Props) => {
  return useQuery(getUserConfirmation(props));
};

export default useGetUserConfirmation;
